import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { CSV_PARSE_DEFAULT_CONFIG } from '../../csv-upload.constants';
import { CsvParsedExtendedResult, SupportedValidator } from '../../csv-upload.interfaces';
import { CsvParseService } from '../../services/csv-parse.service';
import { CsvSupportedValidators } from '../../services/csv-supported-validators';

@Component({
  selector: 'givve-csv-upload-button',
  styleUrls: ['./csv-upload-button.component.scss'],
  templateUrl: './csv-upload-button.component.html',
  standalone: true,
  imports: [MatTooltipModule, MatButtonModule, SvgIconComponent],
})
export class CsvUploadButtonComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() iconKey = 'upload';
  @Input() iconTooltip = 'CSV Import';
  @HostBinding('class.disabled')
  @Input()
  disabled = false;
  @Input() accept = '.csv';
  @Input() validator: string | null = null;
  @Input() iconFontSize: string = '24px';
  @Input() tooltipDisabled: boolean = false;
  @Input() parseCsvDisabled: boolean = false;

  @HostBinding('attr.data-cy') dataCy = 'file-upload-button';

  @Output() fileParsed = new EventEmitter<CsvParsedExtendedResult>();
  @Output() fileAdded = new EventEmitter<File>();

  @ViewChild('fileUpload', { static: true }) fileUploadRef: ElementRef<HTMLElement> | undefined;

  selectedValidator!: SupportedValidator;

  private destroyRef = inject(DestroyRef);

  constructor(
    private parseCsvService: CsvParseService,
    private supportedValidators: CsvSupportedValidators
  ) {}

  ngOnInit() {
    if (this.validator) {
      this.initValidator();
    }
  }

  ngOnChanges({ validator }: SimpleChanges) {
    if (validator && !this.parseCsvDisabled) {
      this.initValidator();
    }
  }

  /**
   * Handle files with browser's native file selection
   */
  onFileAdded(event) {
    event.preventDefault();
    const fileUpload = this.fileUploadRef?.nativeElement as HTMLInputElement;
    fileUpload.onchange = (event: any) => {
      const uploadedFiles = fileUpload.files ?? [];
      for (let index = 0; index < uploadedFiles.length; index++) {
        const selectedFile: File = uploadedFiles[index];
        if (selectedFile) {
          this.parseCsvDisabled ? this.fileAdded.emit(selectedFile) : this.parseCsv(selectedFile);
          event.target.value = '';
        }
      }
    };
    fileUpload.click();
  }

  private initValidator() {
    if (!this.validator && !this.parseCsvDisabled) {
      throw new Error('Component Input validator is not defined');
    }

    const validators = this.supportedValidators.getValidators([this.validator as string]);

    if (validators.length !== 1) {
      throw new Error(`Validator for type ${this.validator} not found`);
    }

    this.selectedValidator = validators[0];
  }

  private parseCsv(selectedFile: File) {
    this.parseCsvService
      .fileToJson(selectedFile, {
        ...CSV_PARSE_DEFAULT_CONFIG,
        headerFields: this.selectedValidator.fields,
        validators: this.selectedValidator.validators,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({ next: this.parseCsvCallback });
  }

  private parseCsvCallback = (result: CsvParsedExtendedResult) => {
    this.fileParsed.emit(result);
  };
}
