import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';

@Component({
  selector: 'givve-file-drop-area',
  templateUrl: './file-drop-area.component.html',
  styleUrls: ['./file-drop-area.component.scss'],
  standalone: true,
  imports: [NgxFileDropModule, NgClass],
})
export class FileDropAreaComponent {
  @Input() accept = '.csv';

  @Input()
  get multiple() {
    return this._multiple;
  }
  set multiple(value: BooleanInput) {
    this._multiple = coerceBooleanProperty(value);
  }
  private _multiple = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @Output() fileAdded = new EventEmitter<File>();

  /**
   * Handle files dropped in the area
   *
   * @param event
   */
  onFileDropped(event: NgxFileDropEntry[]) {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((selectedFile) => {
          this.fileAdded.emit(selectedFile);
        });
      }
    }
  }
}
