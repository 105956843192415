import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_VALIDATORS } from '../csv-upload.constants';
import { SupportedValidator } from '../csv-upload.interfaces';
import { CsvFieldValidators } from './csv-field-validators.service';

@Injectable({ providedIn: 'root' })
export class CsvSupportedValidators {
  translate = inject(TranslateService);
  validators = inject(CsvFieldValidators);

  private readonly cardOrderAdminRevoucheringFields = [
    'emboss_line_1',
    'emboss_line_2',
    'image_id',
    'reference_voucher_token',
    'is_replacement',
    'transfer_balance',
    'inherit_constraints',
    'identifier',
    'first_name',
    'last_name',
    'email',
    'language_code',
    'constraints_regional_plz',
    'constraints_national_merchant_id',
  ];

  private readonly supportedValidators: SupportedValidator[] = [
    {
      key: SUPPORTED_VALIDATORS.VOUCHER_LOCK,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_voucher_lock'),
      fields: ['token'],
      validators: [this.validators.number('token', { range: '{9,16}' })],
    },
    {
      key: SUPPORTED_VALIDATORS.VOUCHER_UNLOCK,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_voucher_unlock'),
      fields: ['token'],
      validators: [this.validators.number('token', { range: '{9,16}' })],
    },
    {
      key: SUPPORTED_VALIDATORS.BALANCE_TRANSFER,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_balance_transfer'),
      fields: ['from', 'to'],
      validators: [
        this.validators.number('from', { range: '{9,16}' }),
        this.validators.number('to', { range: '{9,16}' }),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.VOUCHER_OWNER_MERGE,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_voucher_owner_merge'),
      fields: ['base', 'merge'],
      validators: [
        this.validators.number('base', { range: '{9,16}' }),
        this.validators.number('merge', { range: '{9,16}' }),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.VOUCHER_MOVE,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_voucher_move'),
      fields: ['token', 'from_customer', 'to_customer'],
      validators: [
        this.validators.number('token', { range: '{9,16}' }),
        this.validators.number('from_customer'),
        this.validators.number('to_customer'),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.VOUCHER_UNLOAD,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_voucher_unload'),
      fields: ['token', 'amount'],
      validators: [this.validators.monetary('amount'), this.validators.number('token', { range: '{9,16}' })],
    },
    {
      key: SUPPORTED_VALIDATORS.LOAD_ORDERS,
      text: this.translate.instant('csv_upload.component.csv_upload_content.load_orders'),
      fields: ['token', 'amount'],
      validators: [this.validators.number('token', { range: '{9,16}' }), this.validators.required('amount')],
    },
    {
      key: SUPPORTED_VALIDATORS.REPLACEMENT_VOUCHER,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_set_replacement_voucher'),
      fields: ['old', 'new'],
      validators: [
        this.validators.number('old', { range: '{9,16}' }),
        this.validators.number('new', { range: '{9,16}' }),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.MEMBERS_ROLE,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_upload_members_role'),
      fields: ['email', 'personnel_number', 'roles'],
      validators: [this.validators.email('email'), this.validators.multi('roles')],
    },
    {
      key: SUPPORTED_VALIDATORS.CARD_ORDER_NEW,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_upload_card_order_new'),
      fields: ['first_name', 'last_name', 'emboss_line_1', 'emboss_line_2', 'identifier', 'zip_code'],
      validators: [
        this.validators.required('first_name'),
        this.validators.required('last_name'),
        this.validators.required('emboss_line_1'),
        this.validators.required('emboss_line_2'),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.CARD_ORDER_REPLACEMENT,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_upload_card_order_replacement'),
      fields: ['token', 'first_name', 'last_name', 'emboss_line_1', 'emboss_line_2'],
      validators: [this.validators.number('token', { range: '{9,16}' })],
    },
    {
      key: SUPPORTED_VALIDATORS.CARD_ORDER_ADMIN_RECARDING,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_upload_card_order_admin_recarding'),
      fields: [
        'emboss_line_1',
        'emboss_line_2',
        'image_id',
        'token',
        'identifier',
        'first_name',
        'last_name',
        'email',
        'language_code',
      ],
      validators: [
        this.validators.number('token', { range: '{9,16}' }),
        this.validators.email('email', { allowEmpty: true }),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.CARD_ORDER_ADMIN_REVOUCHERING,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_upload_card_order_admin_revouchering'),
      fields: this.cardOrderAdminRevoucheringFields,
      validators: [this.validators.email('email', { allowEmpty: true })],
    },
    {
      key: SUPPORTED_VALIDATORS.CARD_ORDER_ADMIN_REVOUCHERING_BENEFIT_IN_KIND,
      text: this.translate.instant(
        'csv_upload.component.csv_upload_content.bulk_upload_card_order_admin_revouchering_benefit_in_kind'
      ),
      fields: this.cardOrderAdminRevoucheringFields,
      validators: [
        this.validators.atLeastOneRequired(['constraints_regional_plz', 'constraints_national_merchant_id']),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.CARD_ORDER_ADMIN_REVOUCHERING_LUNCH,
      text: this.translate.instant(
        'csv_upload.component.csv_upload_content.bulk_upload_card_order_admin_revouchering_lunch'
      ),
      fields: this.cardOrderAdminRevoucheringFields,
      validators: [this.validators.required('constraints_regional_plz')],
    },
    {
      key: SUPPORTED_VALIDATORS.UNLOAD_ORDERS,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_upload_unload_orders'),
      fields: ['token', 'amount'],
      validators: [this.validators.number('token', { range: '{9,16}' })],
    },
    {
      key: SUPPORTED_VALIDATORS.SCHEDULED_LOAD_ORDER_YEARLY,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_upload_scheduled_load_order_yearly'),
      fields: ['token', 'amount', 'due_date'],
      validators: [
        this.validators.required('amount'),
        this.validators.number('token', { range: '{9,16}' }),
        this.validators.date('due_date'),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.LOAD_FEE_UPDATE,
      text: this.translate.instant('csv_upload.component.csv_upload_content.bulk_load_fee_update'),
      fields: [
        'customer_number',
        'old_load_fee_min',
        'old_load_fee_percentage',
        'new_load_fee_min',
        'new_load_fee_percentage',
      ],
      validators: [
        this.validators.number('customer_number'),
        this.validators.required('old_load_fee_min'),
        this.validators.required('old_load_fee_percentage'),
        this.validators.required('new_load_fee_min'),
        this.validators.required('new_load_fee_percentage'),
      ],
    },
    {
      key: SUPPORTED_VALIDATORS.VOUCHER_CONFIGURATION_UPDATE_REGIONAL,
      text: this.translate.instant(
        'csv_upload.component.csv_upload_content.bulk_voucher_configuration_update_regional'
      ),
      fields: ['token', 'postal_code'],
      validators: [this.validators.number('token', { range: '{9,16}' }), this.validators.required('postal_code')],
    },
  ];

  getValidators(selectedValidators: string[] = []): SupportedValidator[] {
    if (!selectedValidators.length) {
      return this.supportedValidators;
    }
    return this.supportedValidators.filter((validator: SupportedValidator) =>
      selectedValidators.includes(validator.key)
    );
  }

  getValidator(validatorKey: string): SupportedValidator | undefined {
    return this.supportedValidators.find((v) => v.key === validatorKey);
  }
}
