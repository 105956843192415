import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { bytesToSize, getArrayDiff } from '../csv-upload.utils';

import { CsvValidatorParameters, CsvValidatorResult, Customization } from '../csv-upload.interfaces';
import { CSV_UPLOAD_CONFIG_TOKEN } from '../customizations';
import { CsvColumnTranslateService } from './csv-column-translate.service';

@Injectable({ providedIn: 'root' })
export class CsvDefaultValidators {
  private translate = inject(TranslateService);
  private csvColumnTranslate = inject(CsvColumnTranslateService);
  private config: Customization['configs'] = inject(CSV_UPLOAD_CONFIG_TOKEN);

  fileSize = ({ file }: CsvValidatorParameters): CsvValidatorResult => {
    if ((file?.size ?? 0) > this.config.csvMaxFileSize) {
      return {
        message: this.translate.instant('csv_upload.service.default_validators.file_size_exceeded', {
          MAX_FILE_SIZE: bytesToSize(this.config.csvMaxFileSize),
        }),
      };
    }

    return { message: null };
  };

  entriesLimit = ({ result }: CsvValidatorParameters): CsvValidatorResult => {
    if (result.data.length > this.config.csvEntriesLimit) {
      return {
        message: this.translate.instant('csv_upload.service.default_validators.entries_limit_exceeded', {
          ENTRIES_LIMIT: this.config.csvEntriesLimit,
        }),
      };
    }

    return { message: null };
  };

  emptyFile = ({ result }: CsvValidatorParameters): CsvValidatorResult => {
    if (!result.data.length) {
      return { message: this.translate.instant('csv_upload.service.default_validators.no_entries') };
    }

    return { message: null };
  };

  fileHeaders = ({ config, result }: CsvValidatorParameters): CsvValidatorResult => {
    // expect translated column names; translate to english to be able to compare to config fields
    const resultFields = this.csvColumnTranslate.translateHeaderFieldLabelsToEnglishNameKeys(result.meta.fields);

    if (
      !config?.header ||
      !config?.headerFields?.length ||
      JSON.stringify(config?.headerFields.sort()) === JSON.stringify(resultFields?.sort())
    ) {
      return { message: null };
    }

    const message: string[] = [this.translate.instant('csv_upload.service.default_validators.invalid_file_headers')];

    const missingFields = getArrayDiff(config?.headerFields, resultFields || []);
    const wrongFields = getArrayDiff(resultFields || [], config?.headerFields);

    if (missingFields?.length) {
      const key = 'csv_upload.service.default_validators.missing_file_header_fields';
      const fields = missingFields
        .map((el) => `"${this.csvColumnTranslate.getTranslationForEnglishNameKey(el)}"`)
        .join(', ');
      message.push(this.translate.instant(key, { fields }));
    }

    if (wrongFields?.length) {
      const key = 'csv_upload.service.default_validators.wrong_file_header_fields';
      const fields = wrongFields
        .map((el) => `"${this.csvColumnTranslate.getTranslationForEnglishNameKey(el)}"`)
        .join(', ');
      message.push(this.translate.instant(key, { fields }));
    }

    return { message: message.join(' ') };
  };
}
