export default {
  de: {
    'csv_upload.service.default_validators.file_size_exceeded':
      'Maximale Dateigröße von {{MAX_FILE_SIZE}} ist überschritten',
    'csv_upload.service.default_validators.no_entries': 'Die Datei hat keine Einträge',
    'csv_upload.service.default_validators.no_directories_supported': 'Verzeichnisse werden nicht unterstützt',
    'csv_upload.service.default_validators.entries_limit_exceeded':
      'Maximale Anzahl der Dateieinträge {{ENTRIES_LIMIT}} ist überschritten',
    'csv_upload.service.default_validators.invalid_file_headers': 'Die Datei hat ungültige Header Formate.',
    'csv_upload.service.default_validators.missing_file_header_fields': 'Folgende Spalten fehlen: {{fields}}.',
    'csv_upload.service.default_validators.wrong_file_header_fields': 'Folgende Spalten sind falsch: {{fields}}.',
    'csv_upload.service.csv_field_validators.message': 'Fehlerhafte "{{ index }}" Feldformate in Zeile {{ rows }}',
    'csv_upload.service.csv_field_validators.required': 'Fehlende Werte "{{ index }}" in den Zeilen {{ rows }}',
    'csv_upload.component.csv_upload_content.select_bulk_action': 'Bulk Action* auswählen',
    'csv_upload.component.csv_upload_content.place_csv_file': 'CSV Datei hier ablegen',
    'csv_upload.component.csv_upload_content.or_select_folder': 'oder aus einem Ordner <span>auswählen</span>',
    'csv_file_sample.component.here_you_can_get': 'Hier können Sie eine',
    'csv_file_sample.component.download': 'herunterladen',
    'csv_file_sample.component.download_csv_template': 'CSV Vorlage herunterladen',
    'csv_file_sample.component.here_you_can_get_multiple': 'Hier können Sie die',
    'csv_file_sample.component.download_csv_template_multiple': '{{validator}} CSV Vorlage',
    'csv_upload.component.csv_upload_content.bulk_voucher_lock': 'Karten sperren (CSV token)',
    'csv_upload.component.csv_upload_content.bulk_voucher_unlock': 'Karten entsperren (CSV token)',
    'csv_upload.component.csv_upload_content.bulk_balance_transfer': 'Guthabentransfer (CSV from;to)',
    'csv_upload.component.csv_upload_content.bulk_voucher_owner_merge': 'Karteninhaber zusammenlegen (CSV base;merge)',
    'csv_upload.component.csv_upload_content.bulk_voucher_move':
      'Karten umziehen (CSV token;from_customer;to_customer)',
    'csv_upload.component.csv_upload_content.bulk_voucher_unload': 'Karten entladen (CSV token;amount)',
    'csv_upload.component.csv_upload_content.load_orders': 'Kartenladung (CSV token;amount)',
    'csv_upload.component.csv_upload_content.bulk_set_replacement_voucher': 'Ersatzkarte zuordnen (CSV old;new)',
    'csv_upload.component.csv_upload_content.bulk_upload_members_role':
      'Bulk Upload Mitglieder Rollen (CSV email;personnel_number;roles)',
    'csv_upload.component.csv_upload_content.bulk_upload_card_order_new': 'Bulk upload Karten Bestellung Neu',
    'csv_upload.component.csv_upload_content.bulk_upload_card_order_replacement':
      'Bulk upload Karten Bestellung Ersatzkarte',
    'csv_upload.component.csv_upload_content.bulk_upload_card_order_admin_recarding':
      'Bulk upload Karten Bestellung Recarding',
    'csv_upload.component.csv_upload_content.bulk_upload_card_order_admin_revouchering':
      'Bulk upload Karten Bestellung Revouchering',
    'csv_upload.component.csv_upload_content.bulk_upload_card_order_admin_revouchering_benefit_in_kind':
      'Bulk upload Karten Bestellung Revouchering Benefit in Kind',
    'csv_upload.component.csv_upload_content.bulk_upload_card_order_admin_revouchering_lunch':
      'Bulk upload Karten Bestellung Revouchering Lunch',
    'csv_upload.component.csv_upload_content.bulk_upload_unload_orders': 'Bulk upload Karten Entladung',
    'csv_upload.component.csv_upload_content.bulk_upload_scheduled_load_order_yearly':
      'Bulk upload Ladedauerauftrag jährlich',
    'csv_upload.component.csv_upload_content.bulk_load_fee_update':
      'Preise aktualisieren (CSV customer_number;old_load_fee_min;old_load_fee_percentage;new_load_fee_min;new_load_fee_percentage)',
    'csv_upload.component.csv_upload_content.bulk_voucher_configuration_update_regional':
      'Regionale Kartenkonfiguration aktualisieren (CSV token;postal_code)',
    'csv_upload.component.csv_upload_content.selected_validator_info.load-orders':
      'Laden Sie eine CSV-Datei mit Kartenladungen hoch.',
    'csv_upload.component.csv_upload_content.selected_validator_info.unload-orders':
      'Laden Sie eine CSV-Datei mit Kartenentladungen hoch.',
    'csv_upload.component.csv_upload_content.selected_validator_info.card-order-new':
      'Laden Sie eine CSV-Datei mit Kartenbestellungen hoch.',
    'csv_upload.component.csv_upload_content.selected_validator_info.card-order-replacement':
      'Laden Sie eine CSV-Datei mit Kartenbestellungen hoch.',
    'csv_upload.component.csv_upload_content.selected_validator_info.card-order-admin-recarding':
      'Laden Sie eine CSV-Datei mit Kartenbestellungen hoch.',
    'csv_upload.component.csv_upload_content.selected_validator_info.card-order-admin-revouchering':
      'Laden Sie eine CSV-Datei mit Kartenbestellungen hoch.',
    'csv_upload.component.csv_upload_content.selected_validator_info.card-order-admin-lunch':
      'Laden Sie eine CSV-Datei mit Kartenbestellungen hoch.',
    'csv_upload.component.csv_upload_content.selected_validator_info.card-order-admin-revouchering-benefit-in-kind':
      'Laden Sie eine CSV-Datei mit Kartenbestellungen hoch.',
    'csv_upload.component.csv_upload_content.selected_validator_info.voucher-lock':
      'Sperrt alle Vouchers anhand des Tokens',
    'csv_upload.component.csv_upload_content.selected_validator_info.voucher-unlock':
      'Entsperrt alle Vouchers anhand des Tokens.',
    'csv_upload.component.csv_upload_content.selected_validator_info.balance-transfer':
      'Transfer des gesamten Guthabens von Token in Spalte "from" zu Token in Spalte "to".',
    'csv_upload.component.csv_upload_content.selected_validator_info.voucher-owner-merge':
      'Überträgt Voucher "merge" zum VoucherOwner des Tokens "base".  Der ursprüngliche VoucherOwner von Token "merge" wird dabei gelöscht. VoucherOwner von Token "base" hat anschließend Zugriff auf beide Karten.',
    'csv_upload.component.csv_upload_content.selected_validator_info.voucher-move':
      'Verschiebt Voucher "token" von Kunde in Spalte "from_customer" zu Kunde in Spalte "to_customer"',
    'csv_upload.component.csv_upload_content.selected_validator_info.voucher-unload': 'Not specified.',
    'csv_upload.component.csv_upload_content.selected_validator_info.replacement-voucher':
      'Definiert Token in Spalte "new" als Nachfolger von Token in Spalte "old".',
    'csv_upload.component.csv_upload_content.selected_validator_info.load-fee-update':
      'Ändert die Ladegebühren von Kunde in Spalte "customer" zu "new_load_fee_min" und "new_load_fee_percentage", sofern die aktuellen Werte mit "old_load_fee_min" und "old_load_fee_percentage" übereinstimmen.',
    'csv_upload.component.csv_upload_content.selected_validator_info.voucher-configuration-update-regional':
      'Ändert die regionale Einstellung von Karte in Spalte "token" zur Region innerhalb derer "postal_code" liegt. Nimmt keine Änderung vor, wenn die Karte mehr als 1 EUR Guthaben aufweist.',
    'csv_upload.component.invalid-encoding':
      'Invalid file encoding, please make sure that the file is encoded in UTF-8 format',
  },
};
