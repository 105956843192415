import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CSV_UPLOAD_CONFIG_TOKEN } from '../../customizations';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'givve-csv-file-sample',
  standalone: true,
  imports: [CommonModule, TranslateModule, SvgIconComponent],
  templateUrl: './csv-file-sample.component.html',
  styleUrl: './csv-file-sample.component.scss'
})
export class CsvFileSampleComponent {
  @Input() selectedValidator: string | null | undefined = null;
  @Input() validators: string[] | null = null;
  configs = inject(CSV_UPLOAD_CONFIG_TOKEN);

  onDownloadCsvSample(validator?: string) {
    window.open(`${this.configs.csvDownloadBasePath}${this.selectedValidator || validator}.csv`, '_blank');
  }
}
