<div
  class="csv-upload-button"
  matTooltip="{{ iconTooltip }}"
  matTooltipDisabled="{{ tooltipDisabled }}"
>
  <button
    mat-button
    disableRipple
    [disabled]="disabled"
    (click)="onFileAdded($event)"
  >
    <svg-icon
      key="{{ iconKey }}"
      fontSize="{{ iconFontSize }}"
    ></svg-icon>
    @if (label) {
      <span class="label">{{ label }}</span>
    }
  </button>
  <input
    type="file"
    data-cy="givve-file-upload-input"
    id="file-upload"
    name="file-upload"
    #fileUpload
    accept="{{ accept }}"
    style="display: none"
  />
</div>
