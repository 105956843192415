import { InjectionToken } from '@angular/core';
import { CustomizationConfig } from '../csv-upload.interfaces';
import configs from './configs';
import translations from './translations';

const CSV_UPLOAD_CONFIG_TOKEN = new InjectionToken<CustomizationConfig>('CsvUploadConfig', {
  providedIn: 'root',
  factory: () => Customizations.configs,
});

const Customizations = {
  translations,
  configs,
};

export { CSV_UPLOAD_CONFIG_TOKEN, Customizations };
