import { makeEnvironmentProviders } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { Observable, of } from 'rxjs';
import { mergeWith } from 'lodash-es';

import { CsvDefaultValidators } from './services/csv-default-validators.service';
import { CsvFieldValidators } from './services/csv-field-validators.service';
import { CsvSupportedValidators } from './services/csv-supported-validators';
import { CsvParseService } from './services/csv-parse.service';
import { allSvgIcons } from './svg/all-svg-icons';
import { Customizations, CSV_UPLOAD_CONFIG_TOKEN } from './customizations';
import { Customization, DeepPartial } from './csv-upload.interfaces';
import { CsvColumnTranslateService } from './services/csv-column-translate.service';

export class CsvUploadTranslateLoader implements TranslateLoader {
  getTranslation(
    lang: string,
    translationsOverrides: DeepPartial<Customization['translations']['de']> = {}
  ): Observable<any> {
    return of(mergeWith({}, Customizations.translations[lang], translationsOverrides));
  }
}

export const provideGivveCsvUpload = (configs: DeepPartial<Customization['configs']> = {}) => {
  const mergedConfigs = mergeWith({}, Customizations.configs, configs, (_objValue, srcValue, key) =>
    key === 'validators' && srcValue.length ? srcValue : undefined
  );

  return makeEnvironmentProviders([
    CsvDefaultValidators,
    CsvFieldValidators,
    CsvParseService,
    CsvSupportedValidators,
    CsvColumnTranslateService,
    provideSvgIconsConfig({
      sizes: {
        md: '24px',
      },
      defaultSize: 'md',
      icons: [...allSvgIcons],
    }),
    {
      provide: CSV_UPLOAD_CONFIG_TOKEN,
      useValue: mergedConfigs,
    },
  ]);
};
