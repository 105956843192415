<ngx-file-drop
  [ngClass]="{ disabled: disabled }"
  data-cy="givve-file-drop-area"
  (onFileDrop)="onFileDropped($event)"
  multiple="{{ multiple }}"
  accept="{{ accept }}"
  [disabled]="disabled"
  [directory]="false"
>
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div (click)="!disabled && openFileSelector()" class="open-file-selector-link">
      <ng-content></ng-content>
    </div>
  </ng-template>
</ngx-file-drop>
