<svg-icon key="csv"></svg-icon>
@if (selectedValidator) {
  <span>
    {{ 'csv_file_sample.component.here_you_can_get' | translate }}
    <span
      class="link"
      data-cy="givve-csv-upload-donwload-file-link"
      [ngClass]="{ disabled: !selectedValidator }"
      (click)="selectedValidator && onDownloadCsvSample()"
    >{{ 'csv_file_sample.component.download_csv_template' | translate }}</span
    >
  </span>
} @else if (validators) {
  <span>
    {{ 'csv_file_sample.component.here_you_can_get_multiple' | translate }}
    @for (validator of validators; track validator; let last = $last) {
      <span
        class="link"
        (click)="onDownloadCsvSample(validator)"
      >{{ 'csv_file_sample.component.download_csv_template_multiple' | translate: {validator: validator | titlecase} }}</span>
      <ng-container *ngIf="!last">, </ng-container>
    }
    {{ 'csv_file_sample.component.download' | translate }}
  </span>
}
