import { NgClass } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CSV_PARSE_DEFAULT_CONFIG } from '../../csv-upload.constants';
import { CreateMutable, CsvParsedExtendedResult, SupportedValidator } from '../../csv-upload.interfaces';
import { isUTF8 } from '../../csv-upload.utils';
import { CSV_UPLOAD_CONFIG_TOKEN } from '../../customizations';
import { CsvParseService } from '../../services/csv-parse.service';
import { CsvSupportedValidators } from '../../services/csv-supported-validators';
import { FileDropAreaComponent } from '../file-drop-area/file-drop-area.component';
import { CsvFileSampleComponent } from '../csv-file-sample/csv-file-sample.component';

@Component({
  selector: 'givve-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    SvgIconComponent,
    FileDropAreaComponent,
    MatProgressSpinnerModule,
    NgClass,
    TranslateModule,
    CsvFileSampleComponent,
  ],
})
export class CsvUploadComponent implements OnInit, OnChanges {
  @Output() fileParsed = new EventEmitter<CsvParsedExtendedResult>();
  @Output() selectedFileChange = new EventEmitter<File>();
  @Output() selectedValidatorChange = new EventEmitter<string>();

  @Input() loading: boolean = false;
  @Input({ required: true }) selectedFile: File | null | undefined;
  @Input({ required: true }) selectedValidator: string | null | undefined;
  @Input({ required: true }) validators: string | string[] | undefined;

  private destroyRef = inject(DestroyRef);
  private parseCsvService = inject(CsvParseService);
  private csvSupportedValidators = inject(CsvSupportedValidators);
  private translate = inject(TranslateService);
  configs = inject(CSV_UPLOAD_CONFIG_TOKEN);

  supportedValidators!: SupportedValidator[];

  ngOnInit(): void {
    this.initValidator();
  }

  ngOnChanges({ validators, selectedFile, selectedValidator }: SimpleChanges) {
    if (validators?.previousValue !== validators?.currentValue) {
      this.initValidator();
    }

    if (selectedFile?.previousValue !== selectedFile?.currentValue) {
      this.selectedFile = selectedFile.currentValue;
    }
    if (selectedValidator?.previousValue !== selectedValidator?.currentValue) {
      this.selectedValidator = selectedValidator.currentValue;
      this.selectedFile = null;
    }
  }

  handleValidatorSelection(validator: string) {
    this.selectedValidatorChange.emit(validator);
  }

  onAddFileSystemFile(selectedFile: File) {
    isUTF8(selectedFile).subscribe((isValid) => {
      if (isValid) {
        this.parseCsvFile(selectedFile);
      } else {
        this.fileParsed.emit({
          data: null,
          errors: [{ message: this.translate.instant('csv_upload.component.invalid-encoding') }],
          meta: null,
        });
      }
    });
  }

  private parseCsvFile(selectedFile: File) {
    const currentTypeObj = this.supportedValidators.find((v: SupportedValidator) => v.key === this.selectedValidator);

    if (typeof currentTypeObj !== 'undefined') {
      this.selectedFileChange.emit(selectedFile);

      this.parseCsvService
        .fileToJson(selectedFile, {
          ...CSV_PARSE_DEFAULT_CONFIG,
          headerFields: currentTypeObj.fields as CreateMutable<typeof currentTypeObj.fields>,
          validators: currentTypeObj.validators as CreateMutable<typeof currentTypeObj.validators>,
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({ next: this.parseCsvCallback });
    } else {
      console.error('Unable to select the preferred CSV upload action');
    }
  }

  private initValidator() {
    if (!this.validators || !this.validators?.length) {
      throw new Error('CsvUploadComponent :: @Input validators are not defined or empty');
    }

    this.validators = Array.isArray(this.validators) ? this.validators : [this.validators];
    this.supportedValidators = this.csvSupportedValidators.getValidators(this.validators);

    if (this.validators.length === 1) {
      this.selectedValidatorChange.emit(this.validators[0]);
    }
  }

  private parseCsvCallback = (result: CsvParsedExtendedResult) => {
    this.fileParsed.emit(result);
  };
}
