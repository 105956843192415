import { SortDirection } from '@angular/material/sort';
import { catchError, from, map, Observable, of } from 'rxjs';

/**
 * Compare numbers or string in ascending or descending order
 */
export const compare = (a: number | string, b: number | string, order: SortDirection) => {
  return (a < b ? -1 : 1) * (order === 'asc' ? 1 : -1);
};

/**
 * Converts a number of bytes to a human readable size format
 */
export const bytesToSize = (bytes: number) => {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};

/**
 * return difference of two arrays (elements missing in the second array)
 *
 * @param a
 * @param b
 */
export const getArrayDiff = (a: string[], b: string[]) => {
  return a.filter((element: string) => {
    return !b.includes(element);
  });
};

/**
 * validate that blob is utf-8 encoded
 *
 * @param blob
 */
export const isUTF8 = (blob): Observable<boolean> => {
  const decoder = new TextDecoder('utf-8', { fatal: true });

  return from(blob.arrayBuffer()).pipe(
    map((buffer) => {
      try {
        decoder.decode(buffer as ArrayBuffer);
        return true;
      } catch (e) {
        if (e instanceof TypeError) {
          return false;
        }
        throw e;
      }
    }),
    catchError(() => {
      return of(false);
    })
  );
};
