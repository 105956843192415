import { Injectable, inject } from '@angular/core';
import { Papa, ParseResult, UnparseConfig } from 'ngx-papaparse';
import { UnparseData } from 'ngx-papaparse/lib/interfaces/unparse-data';
import { Observable, Subject, first } from 'rxjs';
import {
  CsvErrorMessage,
  CsvParseExtendedConfig,
  CsvParsedExtendedResult,
  CsvValidatorParameters,
  CsvValidatorResult,
} from '../csv-upload.interfaces';
import { CsvColumnTranslateService } from './csv-column-translate.service';
import { CsvDefaultValidators } from './csv-default-validators.service';

@Injectable({ providedIn: 'root' })
export class CsvParseService {
  subject$ = new Subject<CsvParsedExtendedResult>();

  private csvColumnTranslate = inject(CsvColumnTranslateService);
  private papa = inject(Papa);
  private validators = inject(CsvDefaultValidators);

  defaultValidators: Array<(data: CsvValidatorParameters) => CsvValidatorResult> = [
    this.validators.emptyFile,
    this.validators.entriesLimit,
    this.validators.fileHeaders,
    this.validators.fileSize,
  ];

  fileToJson(file: File, config: CsvParseExtendedConfig): Observable<CsvParsedExtendedResult> {
    this.papa.parse(file, {
      ...config,
      complete: (_result: ParseResult, file) => {
        const { result, errors } = this.validate(file, _result, config);
        this.subject$.next({
          ...result,
          errors: errors.filter((error) => !!error.message),
        });
      },
      error: (error, file) => {
        if (config.error) {
          config.error(error, file);
        }

        console.error(`Error parsing file "${file.name}": `, error);
        this.subject$.next({ data: null, meta: null, errors: [error] });
      },
    });

    return this.subject$.asObservable().pipe(first());
  }

  simpleFileToJson<T>(file: File): Observable<T> {
    return new Observable((observer) => {
      this.papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result: ParseResult) => {
          observer.next(result.data);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  jsonToCsv(data: UnparseData, config: UnparseConfig | undefined): string {
    return this.papa.unparse(data, config);
  }

  validate(
    file: File | undefined,
    parseResult: ParseResult,
    config: CsvParseExtendedConfig
  ): { result: CsvParsedExtendedResult; errors: CsvErrorMessage[] } {
    const result = {
      ...parseResult,
      data: this.csvColumnTranslate.transformParseResultToEnglishNameKeys(parseResult.data),
    };
    let errors: CsvErrorMessage[] = [];

    this.defaultValidators.concat(config?.validators || []).forEach((validatorFn) => {
      if (typeof validatorFn === 'function') {
        errors.push(validatorFn({ result, file, config }) as CsvErrorMessage);
      }
    });

    return { result, errors };
  }
}
