import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { CsvValidatorParameters, CsvValidatorResult } from '../csv-upload.interfaces';
import { CsvColumnTranslateService } from './csv-column-translate.service';

const i18nIsoCountriesNumericCodes = i18nIsoCountries.getNumericCodes();
const i18nIsoCountriesSupportedLangues = i18nIsoCountries.getSupportedLanguages();

@Injectable({ providedIn: 'root' })
export class CsvFieldValidators {
  constructor(
    private translate: TranslateService,
    private csvColumnTranslate: CsvColumnTranslateService
  ) {}

  /**
   * Validate an email field
   */
  email(fieldKey: string, options: Partial<{ allowEmpty: boolean }> = { allowEmpty: false }) {
    const fieldLabel = this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey);
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        if (options.allowEmpty && row[fieldKey] === '') {
          return acc;
        }

        return /^\S+@\S+\.\S+$/.test(row[fieldKey]) ? acc : [...acc, index];
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.message', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }
      return { message: null };
    };
  }

  /**
   * Validates a field that contains multiple comma separated values
   */
  multi(fieldKey: string, options: Partial<{ allowEmpty: boolean }> = { allowEmpty: false }) {
    const fieldLabel = this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey);
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        if (options.allowEmpty && row[fieldKey] === '') {
          return acc;
        }

        const values = row[fieldKey]?.split(',').filter((value) => /^\w$/.test(value));
        return values?.length ? [...acc, index] : acc;
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.message', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }
      return { message: null };
    };
  }

  /**
   * Validate a number field with a specific amount of digits
   */
  number(
    fieldKey: string,
    options: Partial<{ allowEmpty: boolean; range: string }> = { allowEmpty: false, range: '+' }
  ) {
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const fieldLabel = this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey);
      const regex = new RegExp(`^\\d${options.range}$`);

      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        if (options.allowEmpty && row[fieldKey] === '') {
          return acc;
        }

        return regex.test(row[fieldKey]) ? acc : [...acc, index];
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.message', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }
      return { message: null };
    };
  }

  /**
   * Validates a monetary field that always contain a comma or a dot separator following by one or more digits
   */
  monetary(fieldKey: string, options: Partial<{ allowEmpty: boolean }> = { allowEmpty: false }) {
    const fieldLabel = this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey);
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        if (options.allowEmpty && row[fieldKey] === '') {
          return acc;
        }

        return /^\d+[\.|,]\d{1,}$/.test(row[fieldKey]) ? acc : [...acc, index];
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.message', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }

      return { message: null };
    };
  }

  required(fieldKey: string) {
    const fieldLabel = this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey);
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        return row[fieldKey] === '' ? [...acc, index] : acc;
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.required', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }

      return { message: null };
    };
  }

  /**
   * Validates a set of fieldKeys where at least one should be provided
   */
  atLeastOneRequired(fieldKeys: string[]) {
    const fieldLabel = fieldKeys
      .map((fieldKey) => `${this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey)}`)
      .join(' / ');
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        let emptyFieldSum = 0;
        fieldKeys.forEach((fieldKey) => row[fieldKey] === '' && emptyFieldSum++);
        return fieldKeys.length === emptyFieldSum ? [...acc, index] : acc;
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.required', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }

      return { message: null };
    };
  }

  /**
   * Validates a date field that always contain two digit dot two digit dot
   */
  date(
    fieldKey: string,
    options: Partial<{ allowEmpty: boolean; addYear: boolean }> = { allowEmpty: false, addYear: false }
  ) {
    const fieldLabel = this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey);
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        if (options.allowEmpty && row[fieldKey] === '') {
          return acc;
        }

        const regex = options.addYear ? /^\d{2}.\d{2}.\d{4}$/ : /^\d{2}.\d{2}.$/;
        return !regex.test(row[fieldKey]) ? [...acc, index] : acc;
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.message', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }

      return { message: null };
    };
  }

  /**
   * Validates a country code field (ISO 3166-1)
   */
  countryCode(fieldKey: string, options: Partial<{ allowEmpty: boolean }> = { allowEmpty: false }) {
    const fieldLabel = this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey);
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        if (options.allowEmpty && row[fieldKey] === '') {
          return acc;
        }

        return Object.keys(i18nIsoCountriesNumericCodes).includes(row[fieldKey]) ? acc : [...acc, index];
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.message', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }
      return { message: null };
    };
  }

  /**
   * Validates a language code field
   */
  languageCode(fieldKey: string, options: Partial<{ allowEmpty: boolean }> = { allowEmpty: false }) {
    const fieldLabel = this.csvColumnTranslate.getTranslationForEnglishNameKey(fieldKey);
    return ({ result }: CsvValidatorParameters): CsvValidatorResult => {
      const falsyRowIndex = result.data.reduce((acc, row, index) => {
        if (options.allowEmpty && row[fieldKey] === '') {
          return acc;
        }

        return i18nIsoCountriesSupportedLangues.includes(row[fieldKey]) ? acc : [...acc, index];
      }, []);

      if (falsyRowIndex.length) {
        return {
          message: this.translate.instant('csv_upload.service.csv_field_validators.message', {
            index: fieldLabel,
            rows: falsyRowIndex.join(', '),
          }),
          meta: { rowsIndex: falsyRowIndex, fieldKey: fieldLabel },
        };
      }
      return { message: null };
    };
  }
}
