import { Injectable } from '@angular/core';
import { FIELD_TRANSLATIONS } from '../csv-upload.constants';

@Injectable({ providedIn: 'root' })
export class CsvColumnTranslateService {
  private readonly lang = 'de';

  /**
   * replace translated column names with english name property key in object of each row
   * reason is to be able to deal with english property names in further processing of data
   *
   * @param data
   */
  transformParseResultToEnglishNameKeys(data: Record<string, string>[]): any[] {
    const translationMap = this.getCurrentLangTranslations(true);
    return data.map((rowObj: Record<string, string>) => {
      const normalized = {};
      for (let columnName in rowObj) {
        // fall back to original key, if no translation available
        const newKey = translationMap[columnName] || columnName;
        normalized[newKey] = rowObj[columnName];
      }
      return normalized;
    });
  }

  /**
   * get translated column names (object keys) with english name key
   *
   * @param data
   */
  translateHeaderFieldLabelsToEnglishNameKeys(data?: string[]): string[] | undefined {
    return data?.map((headerField: string) => this.getEnglishNameFieldForTranslation(headerField));
  }

  /**
   * get english name column names (object keys) with translations
   *
   * @param data
   */
  translateEnglishNameKeysToLabels(data?: string[]): string[] | undefined {
    return data?.map((headerField: string) => this.getTranslationForEnglishNameKey(headerField));
  }

  /**
   * @param fieldKey
   */
  getTranslationForEnglishNameKey(fieldKey: string): string {
    // fall back to original key, if no translation available
    return this.getCurrentLangTranslations()?.[fieldKey] || fieldKey;
  }

  /**
   * @param fieldKey
   */
  getEnglishNameFieldForTranslation(fieldKey: string): string {
    // fall back to original key, if no translation available
    return this.getCurrentLangTranslations(true)[fieldKey] || fieldKey;
  }

  /**
   * return {"amount": "Betrag"} by default
   * return {"Betrag": "amount"} for inverted true
   *
   * @param inverted
   * @private
   */
  private getCurrentLangTranslations(inverted = false): Record<string, string> {
    const res = {};
    Object.entries(FIELD_TRANSLATIONS).forEach(([fieldKey, langMap]: [string, Record<string, string>]) => {
      const newFieldKey = !inverted ? fieldKey : langMap[this.lang];
      res[newFieldKey] = !inverted ? langMap[this.lang] : fieldKey;
    });
    return res;
  }
}
