<section
  class="csv-upload-wrapper"
  data-cy="givve-csv-upload"
>
  @if (supportedValidators?.length > 1) {
    <mat-form-field>
      <mat-label>
        <i>{{ 'csv_upload.component.csv_upload_content.select_bulk_action' | translate }}</i>
      </mat-label>
      <mat-select
        [(value)]="selectedValidator"
        (selectionChange)="handleValidatorSelection($event.value)"
        data-cy="givve-csv-upload-select-validator"
      >
        @for (validator of supportedValidators; track validator.key) {
          <mat-option
            [value]="validator.key"
            [attr.data-cy]="'givve-csv-upload-select-option-' + validator.key"
          >
            {{ validator.text }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  <ng-content></ng-content>
  @if (selectedValidator) {
    <div class="selected-validator-info">
      <span class="selected-validator-info__icon">
        <svg-icon
          key="info"
          fontSize="18px"
        ></svg-icon>
      </span>
      <span class="selected-validator-info__text">
        {{ 'csv_upload.component.csv_upload_content.selected_validator_info.' + selectedValidator | translate }}
      </span>
    </div>
    <givve-file-drop-area
      (fileAdded)="onAddFileSystemFile($event)"
      [disabled]="!selectedValidator"
    >
      @if (loading === true) {
        <mat-spinner [diameter]="35"></mat-spinner>
      } @else {
        <div class="file-drop-area-content">
          <div class="file-drop-area-content__icon">
            <svg-icon
              key="upload"
              fontSize="22px"
            ></svg-icon>
          </div>
          <div class="file-drop-area-content__text">
            @if (selectedFile?.name) {
              <div class="big-text">{{ selectedFile?.name }}</div>
            } @else {
              <div class="big-text">{{ 'csv_upload.component.csv_upload_content.place_csv_file' | translate }}</div>
              <div
                class="small-text"
                [innerHTML]="'csv_upload.component.csv_upload_content.or_select_folder' | translate"
              ></div>
            }
          </div>
        </div>
      }
    </givve-file-drop-area>
    <givve-csv-file-sample [selectedValidator]="selectedValidator"/>
  }
</section>
