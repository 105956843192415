import { ParseConfig } from 'ngx-papaparse';

export const CSV = {
  ENTRIES_LIMIT: 1000000,
  MAX_FILE_SIZE: 10485760, // 10MB
} as const;

export const CSV_PARSE_DEFAULT_CONFIG: ParseConfig = {
  header: true,
  delimiter: ';',
  skipEmptyLines: true,
} as const;

export const SUPPORTED_VALIDATORS = {
  VOUCHER_LOCK: 'voucher-lock',
  VOUCHER_UNLOCK: 'voucher-unlock',
  BALANCE_TRANSFER: 'balance-transfer',
  VOUCHER_OWNER_MERGE: 'voucher-owner-merge',
  VOUCHER_MOVE: 'voucher-move',
  VOUCHER_UNLOAD: 'voucher-unload',
  REPLACEMENT_VOUCHER: 'replacement-voucher',
  MEMBERS_ROLE: 'members-role',
  CARD_ORDER_NEW: 'card-order-new',
  CARD_ORDER_REPLACEMENT: 'card-order-replacement',
  CARD_ORDER_ADMIN_RECARDING: 'card-order-admin-recarding',
  CARD_ORDER_ADMIN_REVOUCHERING: 'card-order-admin-revouchering',
  CARD_ORDER_ADMIN_REVOUCHERING_BENEFIT_IN_KIND: 'card-order-admin-revouchering-benefit-in-kind',
  CARD_ORDER_ADMIN_REVOUCHERING_LUNCH: 'card-order-admin-revouchering-lunch',
  UNLOAD_ORDERS: 'unload-orders',
  LOAD_ORDERS: 'load-orders',
  SCHEDULED_LOAD_ORDER_YEARLY: 'scheduled-load-order-yearly',
  LOAD_FEE_UPDATE: 'load-fee-update',
  VOUCHER_CONFIGURATION_UPDATE_REGIONAL: 'voucher-configuration-update-regional',
} as const;

export const FIELD_TRANSLATIONS: Record<string, Record<string, string>> = {
  token: {
    de: 'Token',
  },
  amount: {
    de: 'Betrag',
  },
  first_name: {
    de: 'Vorname',
  },
  last_name: {
    de: 'Nachname',
  },
  emboss_line_1: {
    de: 'Prägezeile 1',
  },
  emboss_line_2: {
    de: 'Prägezeile 2',
  },
  identifier: {
    de: 'Personalnummer',
  },
  zip_code: {
    de: 'Postleitzahl',
  },
  due_date: {
    de: 'Ladedatum',
  },
  description: {
    de: 'Beschreibung',
  },
  image_id: {
    de: 'Image Id',
  },
  email: {
    de: 'Email',
  },
  address_line_1: {
    de: 'Strasse',
  },
  address_line_2: {
    de: 'Adresszusatz',
  },
  city: {
    de: 'Ort',
  },
  country_code: {
    de: 'Country Code (ISO 3166-1)',
  },
  language_code: {
    de: 'Language Code',
  },
  product_id: {
    de: 'Product Id',
  },
  reference_voucher_token: {
    de: 'Bezugskarte',
  },
  is_replacement: {
    de: 'Ersatzkarte?',
  },
  transfer_balance: {
    de: 'Guthaben transferieren?',
  },
  inherit_constraints: {
    de: 'Einschränkungen kopieren?',
  },
  constraints_regional_plz: {
    de: 'Regionale Einschränkung PLZ',
  },
  constraints_national_merchant_id: {
    de: 'National Einschränkung Merchant Id',
  },
} as const;
